<!--首页-->
<template>
  <div>
    <div style="padding: 20px 0">
      <!-- 我是首页 -->
      <!--导航-->
      <div class="main" style="border-radius: 14px;background: #fff;margin-bottom:20px;display:flex;flex-direction:column">
        <div class="title" >
              <div></div>
              <div>数据总览</div>
          </div>
          <div style="display:flex;justify-content:space-around;padding-bottom: 20px;">
            <div style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle1%20.png');background-size:100% 100%;width:23%;height:163px;color:#fff;justify-content: space-around;">
              <div style="font-size:48px;margin-left:25px">{{traineeCount}}</div>
              <div style="font-size:18px;margin-left:25px">总用户量</div>
            </div>
            <div style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle2.png');background-size:100% 100%;width:23%;;height:163px;color:#fff;justify-content: space-around;">
              <div style="font-size:48px;margin-left:25px">{{traineeVipCount}}</div>
              <div style="font-size:18px;margin-left:25px">会员数量</div>
            </div>
            <div style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle3.png');background-size:100% 100%;width:23%;;height:163px;color:#fff;justify-content: space-around;">
              <div style="font-size:48px;margin-left:25px">{{traineeMaxLevelCount}}</div>
              <div style="font-size:18px;margin-left:25px">通关人数</div>
            </div>
            <div style="display:flex;flex-direction:column;position:relative;background-image:url('https://sihuankangyuan.cn/user/resource/icon/Pctitle4.png');background-size:100% 100%;width:23%;;height:163px;color:#fff;justify-content: space-around;">
              <div style="font-size:48px;margin-left:25px">{{mentorCount}}</div>
              <div style="font-size:18px;margin-left:25px">导师数量</div>
            </div>
          </div>
        <!-- <img src="../images/title1.png" alt=""  @click='go1'/>
        <img src="../images/title2.png" alt=""  @click='go2'/>
        <img src="../images/title3.png" alt=""  @click='go3'/>
        <img src="../images/title4.png" alt=""  @click='go4'/> -->
        
      </div>
      <!--数据展示图-->
      <!-- <div class="dataimg">
        <div class="left" style="border-radius: 14px">
          <div>
            <div class="title">
              <div></div>
              <div>题目数量</div>
            </div>
          </div>
          <div>
            <div
              id="chart2"
              style="width: 322px; height: 322px"
              ref="chart2"
            ></div>
            <div class="lefttext">
              <div style="display: flex">
                <div class="end"></div>
                <div>题目数量:</div>
                <div>{{questionCount}}个</div>
              </div>
              <div style="display: flex">
                <div class="unfinisher" style="background:#85FFF7"></div>
                <div>视频数量:</div>
                <div>{{contentVideoCount}}个</div>
              </div>
              
              <div style="display: flex;justify-content:center">
                <div>图文数量</div>
                <div>{{contentRichTextCount}}个</div>
              </div>
              
            </div>
          </div>
        </div>
         <div class="left" style="border-radius: 14px;margin-left:20px">
          <div>
            <div class="title">
              <div></div>
              <div>用户数量</div>
            </div>
          </div>
          <div>
            <div
              id="chart3"
              style="width: 322px; height: 322px"
              ref="chart3"
            ></div>
            <div class="lefttext">
              <div style="display: flex">
                <div class="end" style="background:#79DAFE"></div>
                <div>用户数量:</div>
                <div>{{userCount}}人</div>
              </div>
              <div style="display: flex">
                <div class="unfinisher"></div>
                <div>学员数量:</div>
                <div>{{traineeCount}}人</div>
              </div>
              
                <div style="display: flex;justify-content:center">
                <div>导师数量</div>
                <div>{{mentorCount}}人</div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div> -->
      <!--最近考试-->
      <div class="bottom">
        <div class="bordertitle">
          <div>
            <div class="line"></div>
            <div>美容师学习数据</div>
          </div>
          <div>
            <el-select v-model="brandId" placeholder="品牌筛选"   class="cinput ac" @change="list">
                <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
          <el-select v-model="storeId" placeholder="门店筛选" clearable  class="cinput ac" @change="list" style="margin-left:20px">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
          </div>
        </div>
      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 350px"
        
      >
      
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="name" label="美容师名称" align="center" >
        </el-table-column>
        <el-table-column prop="levelName" align="center" label="美容师等级" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="studyTimeMinutes" align="center" label="学习时长" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              {{scope.row.studyTimeMinutes | stime}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      </div>
      <!-- <div style="margin:0 auto; padding:20px 0;">
          <a  href=" " style="display:flex;text-decoration:none;height:20px;line-height:20px;align-items: center;justify-content: center;">
            <img src="../images/record.png" style="width:20px"/>
            <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;text-align:center">浙公网安备 33010202002041号 </p >
            </a>
          <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;text-align:center">浙ICP备2021037496号-1 </p >
          </div> -->
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import {getStatistic,storepageList,homebeauticianList} from '../apis/index'
export default {
  data() {
    return {
       pageIndex: 1,
      pageSize: 10,
      total:0,
      storeId:'',
      storeList:[],
      brandList:[],
      brandId:1,
      tableData: [
        
      ],
      questionCount:0,
      contentVideoCount:0,
      contentRichTextCount:0,
      traineeCount:0,
      traineeCount:0,
      mentorCount:0
    };
  },
  filters:{
    stime(value){
      return parseInt(value/60) + '小时'+value%60 + '分'
    }
  },
  mounted() {
    // this.init();
    this.getMap().then(res=>{
      this.init2();
      this.init3();
    })
    
  },
  created(){
    // this.onclick()
    this.brandList=this.$store.state.brandList
    this.store()
    this.list()
    // console.log(this.$store.state.permissionsList)
  },
  methods: {
     details(row){//查看详情
      this.$router.push({path:'/beauticiandetails',query:{row:JSON.stringify(row)}})
    },
      handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
    async list(){
      const params={
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        brandId:this.brandId,
        storeId:this.storeId
      }
     const {data}=await homebeauticianList(params)
     if(data.code==200){
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
    },
     async store(){//获取门店列表
      const params={
        pageIndex:1,
        pageSize:9999
      }
     const {data}=await storepageList(params)
     if(data.code==200){
       this.storeList=data.data.records
     }else{
       this.$message.error(data.msg)
     }
    },
    go1(){
      this.$store.commit('setIndex',6)
      this.$router.push({path:'/textIndex'})
    },
     go2(){
        this.$store.commit('setIndex',3)
      this.$router.push({path:'/people'})
    },
     go3(){
        this.$store.commit('setIndex',2)
      this.$router.push({path:'/exercises'})
    },
     go4(){
        this.$store.commit('setIndex',1)
      this.$router.push({path:'/content'})
    },
   async getMap(){//获取图表数据信息
     const {data}=await getStatistic()
     if(data.code==200){
      console.log(data.data)
      const {questionCount,contentVideoCount,contentRichTextCount,traineeCount,mentorCount,traineeVipCount,traineeMaxLevelCount} = data.data
      this.questionCount=questionCount
      this.contentVideoCount=contentVideoCount
      this.contentRichTextCount=contentRichTextCount
      this.traineeCount=traineeCount
      this.traineeCount=traineeCount
      this.mentorCount=mentorCount
      this.traineeVipCount=traineeVipCount
      this.traineeMaxLevelCount=traineeMaxLevelCount
     }else{
       this.$message.error(data.msg)
     }
    
    },
    init() {
      //2.初始化
      this.chart = this.$Echarts.init(this.$refs.chart);
      //3.配置数据
      var option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            // name: 'Access From',
            type: "pie",
            // radius: "50%",
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            data: [
              { value: 1000, name: "已完成", itemStyle: { color: "#68DDD5" } },
              { value: 100, name: "未完成", itemStyle: { color: "#FFE692" } },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      };
      
      // 4.传入数据
      this.chart.setOption(option);
    },
    // onclick(){
    //   let options={
    //     text:'拼命加载中'
    //   }
    //     let loadingInstance = Loading.service(options);
    //     setTimeout(() => {
    //       this.init2()
    //       loadingInstance.close();
    //     }, 1000);
          
    // },
    init2() {
      //2.初始化
      this.chart = this.$Echarts.init(this.$refs.chart2);
      //3.配置数据
      var option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            // name: 'Access From',
            type: "pie",
            // radius: "50%",
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            data: [
              {value: this.questionCount,name: "题目数量",itemStyle: { color: "#ffe692" },},
              { value: this.contentVideoCount, name: "视频数量", itemStyle: { color: "#85FFF7" } },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      };
      // 4.传入数据
      this.chart.setOption(option);
    },
    init3() {
      //2.初始化
      this.chart = this.$Echarts.init(this.$refs.chart3);
      //3.配置数据
      var option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            // name: 'Access From',
            type: "pie",
            // radius: "50%",
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            data: [
              {value: this.traineeCount,name: "学员数量",itemStyle: { color: "#FF8D9F" },},
              { value: this.traineeCount, name: "用户数量", itemStyle: { color: "#79DAFE" } },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      };
      // 4.传入数据
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  display: flex;
  justify-content: space-between;
  img {
    width: 290px;
  }
}
.item {
  display: flex;
  align-items: center;
  // width: 290px;
  width: 24%;
  height: 143px;
  border-radius: 20px;
  justify-content: space-evenly;
  color: white;
  img {
    width: 88px;
    height: 88px;
  }
  div {
    display: flex;
  }
}
.backwriting {
  background: #fe7e92;
}
.backteacher {
  background: #7d95ff;
}
.backtopic {
  background: #4eded4;
}
.backlearning {
  background: #57d1fe;
}
.fs {
  font-size: 19px;
  line-height: 60px;
}
.fl {
  font-size: 33px;
}
.dataimg {
  margin: 26px 0;
  display: flex;
  justify-content: space-between;
}
.left {
  // width: 60%;
  width: 764px;
  background: #fff;
  // height: 100%;
  height: 463px;
  display: flex;
  // align-items: center;
  flex-direction: column;
}
.left > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  // flex-direction: column;
  // height: 300;
}
.left > div:last-child > div {
  display: flex;
  font-size: 14px;
  color: #000;
  flex-direction: column;
}
.end {
  width: 18px;
  height: 18px;
  background: #ffe692;
  border-radius: 10px;
  margin-right: 10px;
}
.unfinisher {
  width: 18px;
  height: 18px;
  background: #FF8D9F;
  border-radius: 10px;
  margin-right: 10px;
}
.title {
  display: flex;
  margin: 18px;
  align-items: center;
}
.title > div:first-child {
  width: 9px;
  height: 22px;
  border-radius: 10px;
  background: #80dfff;
  margin-right: 10px;
}
.title > div:last-child {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.lefttext > div:nth-child(2) {
  margin: 35px 0;
}
.lefttext {
  // align-items: center;
}
.bottom {
  border-radius: 14px;
  padding: 19px 18px;
  background: #fff;
}
.line {
  width: 9px;
  height: 22px;
  background: #72daff;
  border-radius: 6px;
}
.bordertitle {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
}
.bordertitle > div:first-child {
  display: flex;
}
.bordertitle > div:first-child > div:first-child {
  margin-right: 11px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
</style>
